<!-- Loader.vue -->
<template>
    <div v-if="isLoading" class="loader-overlay">
      <img src="@/assets/mygmt_v2_images/Loader.gif" alt="Loading..." class="loader-gif" />
    </div>
  </template>
  
  <script>
  import { EventBus } from '@/event-bus.js';
  
  export default {
    data() {
      return {
        isLoading: false
      };
    },
    created() {
      // Listen for load and unload events
      EventBus.$on('load', this.showLoader);
      EventBus.$on('unload', this.hideLoader);

      setTimeout(()=>{
        if (this.isLoading) {
            this.hideLoader()
        }
      }, 20000)
    },
    beforeDestroy() {
      // Clean up event listeners
      EventBus.$off('load', this.showLoader);
      EventBus.$off('unload', this.hideLoader);
    },
    methods: {
      showLoader() {
        if (this.isLoading) return
        this.isLoading = true;
      },
      hideLoader() {
        if (!this.isLoading) return
        this.isLoading = false;
      }
    }
  };
  </script>
  
  <style scoped>
  .loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.50);
    backdrop-filter: blur(3.05px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Make sure it's above everything else */
  }
  
  .loader-gif {
    width: 320px;
    height: 102px;

  }
  </style>
  