<template>
    <div  class="relative">
    <div class="amount-container">
      <h4>{{$ml.get('transfer.titles.chooseAmount')}}</h4>
      <div @click.stop="$refs.numberInput.focus(); isCalculating = true;previousState.amount = amount"
        class="mock-input flex align-center justify-between">
        <input inputmode="numeric" ref="numberInput" type="text" v-model="formattedAmount" @input="formatAmount"
          @keyup.enter="calculate()" maxlength="35" max="6" min="0">
        <div class=" flex justify-between" style="width: 85px;height: 100%;">
          <div @click.stop="handleOpenCurrencySelector"
            class="relative flex full-center  justify-between" :class="{'pointer' : showDesktopMenu}" style="padding: 0px 10px;height: 100%;width: 100%;">
            <img style="width: 20px;height: 20px;" :src="getFlagByCurrency(selectedCurrency)" alt="">
            {{ selectedCurrency }}
            <div v-if="showCurrencySelector" >
              <div class="currency-select-desktop">
                <div v-for="currency of allowedCurrencies" @click.stop="changeCurrency(currency)"
                  class="currency-options-container flex justify-between align-center" >
                  <div class=" flex">
                    <img class="country-img" style="width: 20px;height: 20px;" :src="getFlagByCurrency(currency)"
                      alt="" srcset="">
                    <div class=""> {{ currency }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div  v-if="showCurrencySelector"  class="closer" @click.stop="showCurrencySelector=false"></div>
          
            <img v-if="showDesktopMenu" src="../../../../assets/mygmt_v2_images/Right-Chevron.svg" style="transform: rotate(90deg);" alt="">
            <div v-else></div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!isCalculating && filterOptions && filterOptions.length">
      <h4> Choose transfer method</h4>
      <div v-if="filterOptions.length > 2" class="method-filter-container flex">
        <div v-for="method of filterOptions" @click="setSelectedMethod(method)"
          class="method flex flex-column full-center pointer" :class="{ 'selected-method': selectedMethod == method }">
          <div class="img-container full-center">
            <img :src="getIconByName(method)" alt="">
          </div>
          <div class="text-container">
            {{ $ml.get('transfer.deliveryOptions.' + method) }}
          </div>
        </div>
      </div>


      <div class="flex flex-column">
        <div v-for="(deliveryOption , index) of fiteredDeliveryOptions"
          class="delivery-options-container  flex flex-column justify-between" :class="{'grey-unaviable': deliveryOption.unAviable, 'recent-transaction': deliveryOption.isRecentTransaction}">
          <div class="option-header flex align-center">
            <img style="width: 24px; height: 24px;" :src="getIconByValue(deliveryOption)" alt="">
            <div> {{ $ml.get('transfer.deliveryOptions.' + deliveryOption.transactionClass) }} {{ correspondentName(deliveryOption)  }} </div>

            <Tag v-if="deliveryOption.isPopular" style="margin-left: 10px;" backgroundColor="#E3FFE6" textColor="#287115" text="Popular"></Tag>
            <Tag v-if="deliveryOption.unAviable" style="margin-left: 10px;" backgroundColor="#FFF0CB" textColor="#BF7E1D" text="Unaviable"></Tag>
            <Tag v-if="deliveryOption.isRecentTransaction" style="margin-left: 10px;" backgroundColor="#E3F3FF" textColor="#152F71" text="Recently used"></Tag>

          </div>
          <div v-if="!deliveryOption.unAviable" class="option-info">
            <div class="flex justify-between">
              <div>I send:</div>
              <div> {{ deliveryOption.amount | formatComma}} {{ deliveryOption.currency }}</div>
            </div>
            <div class="flex justify-between">
              <div>Comission:</div>
              <div> {{ deliveryOption.commission | formatComma }}  {{ deliveryOption.currency }}</div>
            </div>
            <div v-if="deliveryOption.conversionRate !== 1 && deliveryOption.estimatedExchangeRate !==1" class="flex justify-between">
              <div>Exchange rate:</div>
              <div> {{ deliveryOption.conversionRate || deliveryOption.estimatedExchangeRate  | formatComma}}</div>
            </div>
            <div class="flex justify-between bold">
              <div>I pay:</div>
              <div> {{ deliveryOption.amount  + deliveryOption.commission | formatComma }}  {{ deliveryOption.currency }}</div>
            </div>

            <div class="flex justify-between">
              <div>They recieve:</div>
              <div> {{ deliveryOption.totalReceiveAmount | formatComma }} {{ deliveryOption.payoutCurrency }} </div>
            </div>
          </div>
          <div class="error-message" v-else>
                <div v-if="deliveryOption.amountLimited">
                  The amount you wish to send exceeds the maximum transfer limit set by the provider.
                </div>
                <div v-else>
                  Correspondent is locked.
                </div>
          </div>
          <div v-if="!deliveryOption.unAviable" class="option-button">
            <button @click="setTransactionClass(index)" class="btn">{{setSelectLabel(deliveryOption)}}</button>
          </div>
          <div v-else></div>
        </div>
      </div>
    </div>
    <div class="" style="height: 44vh;" v-if="isCalculating">
      <div class="flex flex-column full-center">
        <button @click="calculate();" class="calculate-btn btn">Calculate</button>
        <div class="cash-back flex  align-center w-100">

          <div><img src="../../../../assets/mygmt_v2_images/Present-Cashback-Sparkling.svg" alt="" srcset=""></div>
          <div>  
           <div>You have ₪50 cashback available for use.</div>
           <div>Minimum amount to use: ₪10</div>
          </div>
        </div>
      </div>
    </div>

    <lookup style="display: none;" @ready="lookupReady" ref="lookup" :logged-in="true"></lookup>

    <Drawer ref="currencySelectionDrawer" :title="'Select currency'" :drawerId="'currencySelectionDrawer'"
      class="drawer-wrapper-card">
      <template v-slot:content>
        <div>
          <div v-for="currency of allowedCurrencies" @click="changeCurrency(currency)"
            class="currency-options-container flex justify-between align-center">
            <div class=" flex">
              <img class="country-img" style="width: 20px;height: 20px;" :src="getFlagByCurrency(currency)" alt=""
                srcset="">
              <div class=""> {{ currency }}</div>
            </div>
            <img src="../../../../assets/mygmt_v2_images/Right-Chevron-blue.svg" alt="">
          </div>
        </div>
      </template>
    </Drawer>

    <div  v-if="$refs.currencySelectionDrawer && $refs.currencySelectionDrawer.show"  class="closer backdrop line-flex" @click.stop="$refs.currencySelectionDrawer.closeDrawer= false"></div>

  </div>
</template>
<script>
import _ from 'lodash';
import Lookup from '../../../../components/Lookup.vue';
import { getCountryByIso, isApp, isMobile } from '../../../../services/utils';
import Drawer from '../../../../components/Drawer.vue';
import Tag from './Tag.vue';
import { EventBus } from '../../../../event-bus';

const icons = {
  CASH_BACK: 'fas fa-gift',
  MAX_LOAD: 'fa fa-cc-visa',
  CARD_DEPOSIT: 'fa fa-credit-card',
  PAYMENT: 'fas fa-hand-holding-usd',
  BANK_TRANSFER: 'fa fa-university bank',
  WALLET_TRANSFER: 'fas fa-wallet'
}

const defaultCurrencies = ['ILS' , 'USD', 'EUR']

export default {
  name: 'MethodAndAmountSelection',
  components: { Lookup, Drawer , Tag },
  props: {
    previousStep: {
      type: String,
      required: true,
    }
  },

  beforeCreate(){
      EventBus.$emit('load');
  },

  created() {
    this.selectedCountry = getCountryByIso(this.$store.state.transfer.destinationCountry)
    this.cloneInfo = this.$store.state.transfer.cloneInfo
    this.selectedBeneficiaryId = this.$store.getters.getSelectedBeneficiary
    this.$store.commit('setCurrentOption', null);
    this.selectedCurrency = this.selectedCountry.defaultCurrency
    this.previousState.currency = this.selectedCurrency

  },
  
  data() {
    return {
      amount: '',
      previousState: {amount:'', currency:''},
      isCalculating: false,
      selectedMethod: 'ALL',
      selectedCurrency: '',
      bla: ['euro', 'shekelz'],
      showCurrencySelector: false,
      isMobileDevice: isMobile(),
      name: 'MethodAndAmountSelection',
      cloneInfo: null,
      selectedBeneficiaryId: null,
    };
  },
  computed: {
    fiteredDeliveryOptions() {
      if (this.selectedMethod === 'ALL') return this.processedDeliveryOptions
      return this.processedDeliveryOptions.filter((i) => i.transactionClass.includes( this.selectedMethod.split('_')[0]) )

    },
    allowedCurrencies() {
    return this.selectedCountry.allowedCurrencies.includes('ILS') ? this.selectedCountry.allowedCurrencies : ['ILS',...this.selectedCountry.allowedCurrencies]
    },
  
    processedDeliveryOptions() {
    const deliveryOptions = _.cloneDeep(this.deliveryOptions); // Clone to avoid mutation
    const recentTransactions = this.$store.getters.getCachedTransactions;
    
    let recentDeliveryOption = null;
    let popularOption = null;

    deliveryOptions.forEach(option => {
      this.markUnAviable(option);
    });
    
    if (this.selectedBeneficiaryId) {
      recentDeliveryOption = this.findRecentByBeneficiary(recentTransactions, this.selectedBeneficiaryId, deliveryOptions);
    }

    if (!recentDeliveryOption) {
      recentDeliveryOption = this.findRecentRegularly(recentTransactions, deliveryOptions);
    }

    popularOption = this.findPopularOption(deliveryOptions);

    deliveryOptions.forEach(option => {
      if (this.cloneInfo && this.cloneInfo.transactionClass && this.cloneInfo.correspondentName  && option.transactionClass === this.cloneInfo.transactionClass && option.correspondentName === this.cloneInfo.correspondentName) {
        option.isRecentTransaction = true;
        recentDeliveryOption = option;
      }

      if (recentDeliveryOption === option) {
        option.isRecentTransaction = true;
      }
    });

    if (popularOption && (!recentDeliveryOption || popularOption !== recentDeliveryOption)) {
      popularOption.isPopular = true;
    }

    return _.sortBy(deliveryOptions, option => {
      if (option.isRecentTransaction) return 0;   
      if (option.isPopular) return 1;             
      if (!option.unAviable) return 2;            
      return 3;                                   
    });
  },
   transferError(){
    return this.$store.getters.getTransferError
   },

  
    showDesktopMenu(){
    return this.selectedCountry.allowedCurrencies.length > 1 
    },
    beneficiaries() {
      return Object.filter(this.$store.getters.getListBeneficiary, item => {
        return (item.countryIso3.indexOf(this.selectedCountry.iso ) > -1);
      });
    },

    deliveryOptions() {
      return this.$store.getters.getTransferStep1Response || [];
    },
    filterOptions() {
      const deliveryOptionsUnique = this.removeDuplicatesByFirstWord(this.deliveryOptions.map((i) => i.transactionClass))
      return deliveryOptionsUnique
    },
    getCurrencyIcon() {
      const currenciesIconMap = {}
    },
    formattedAmount: {
      get() {
        return this.formatNumber(this.amount);
      },
      set(newValue) {
        this.amount = newValue.replace(/[^0-9.]/g, '');
        this.$store.commit('setLookupAmount', this.amount);

      }
    }
  },
  watch: {
    // amount(newValue){
    // this.amount() = newValue + 'bla'
    // }
    selectedCurrency(newval, oldval) {
      if (oldval && newval) {  
        this.previousState.currency = oldval
        if (this.isExoticCurrency(newval)) {
         this.$refs.lookup.changeAmountTypeByVar(false);
         this.$store.commit('setLookupCurrency', 'ILS');
         this.$refs.lookup.setCurency('ILS');
       } else {
        this.$store.commit('setLookupCurrency', newval);
        this.$refs.lookup.setCurency(newval);
        this.$refs.lookup.changeAmountTypeByVar(true);
       }
       this.$store.commit('setLookupAmount', this.amount);
        if (this.isCalculating) return
        console.log("🚀 ~ selectedCurrency ~ this.amount:", this.amount);
        this.$refs.lookup.setAmount(this.amount);
        this.$refs.lookup.doLookup();
      }
    },
    transferError(newval,oldval) {
    if (newval === 'NO_DELIVERY_OPTIONS') {
      this.$emit('changeStep', 0)
    }
    }

  },
  mounted() {
    const { defaultAmount, defaultCurrency } = this.selectedCountry
    console.log("🚀 ~ mounted ~ defaultCurrency:", defaultCurrency)
    console.log("🚀 ~ mounted ~ this.$store.getters.getLookupAmount:", this.$store.getters.getLookupAmount)

  
    // this.amount = this.previousStep === 'BeneficiarySelection' ? this.$store.getters.getLookupAmount : defaultAmount
    // console.log("🚀 ~ mounted ~ this.amount111111111111111111111111111:", this.amount)
    // this.$refs.lookup.setAmount(this.amount);
    // this.$refs.lookup.setCurency(defaultCurrency);
    // this.$refs.currencySelectionDrawer.openDrawer()
    // this.$store.commit('setLookupAmount', this.amount);
    // this.$store.commit('setLookupCurrency', defaultCurrency);

    // console.log('drawer', this.$refs.currencySelectionDrawer);


  },
  beforeDestroy() {
    // this.$refs.lookup.setAmount(null);
    // this.$refs.lookup.setCurency(null);
    // this.$store.commit('setLookupAmount',null);
    // this.$store.commit('setLookupCurrency', null);
   },
  methods: {
    isOverLimit(deliveryOption) {
    return this.amount > deliveryOption.amountLimit
    },
   
    iconClass(item) {
      return item.transactionClass === 'WALLET_TRANSFER' && item.destinationCountry === 'CHN' ? item.correspondentIdentifier === 'THUNES_WECHATPAY' ? 'fa fa-weixin' : 'fab fa-alipay' : (icons[item.transactionClass] || 'fa fa-money')
    },
    getIconByValue(deliveryOption, item) {
    if (!deliveryOption || !deliveryOption.transactionClass) return;
    let icon = deliveryOption.transactionClass;
    if ((icon === 'WALLET_TRANSFER' || icon === 'CARD_DEPOSIT') && deliveryOption.destinationCountry === 'CHN') {
      icon = deliveryOption.correspondentName || 'WALLET_TRANSFER'
    }
    return this.getIconByName(icon);
},  
getIconByName(iconName) {
  if (iconName === 'WALLET_DEPOSIT' || iconName === 'WALLET_TO_WALLET') {
        iconName = 'WALLET_TRANSFER';
    }
  return require(`../../../../assets/mygmt_v2_images/transfer_methods/${iconName}.svg`);
},
correspondentName(deliveryOption) {
      if (deliveryOption.destinationCountry === 'CHN') {
        return deliveryOption.transactionClass === 'WALLET_TRANSFER' ? '- ' + (deliveryOption.correspondentIdentifier === 'THUNES_WECHATPAY' ? this.$ml.get('NAME_WECHATPAY') : this.$ml.get('NAME_ALIPAY')) : deliveryOption.transactionClass === 'CARD_DEPOSIT' ? '- ' + this.$ml.get('NAME_UNIONPAY') : '';
      } else if (deliveryOption.transactionClass === 'WALLET_TRANSFER' && deliveryOption.destinationCountry === 'IND') {
        return '- ' + this.$ml.get('NAME_UPI')
      }

      if (deliveryOption.transactionClass === 'CASH_TRANSFER') {
        return deliveryOption.correspondentName
      }
    },
    setSelectLabel(deliveryOption){
    if (this.cloneInfo &&  this.cloneInfo.transactionClass && deliveryOption.isRecentTransaction ) {
    return 'Send Again'
    } else {
      return 'Select'
    }
    },
    handleOpenCurrencySelector(){
      if (!this.showDesktopMenu) return
      this.isMobileDevice ? this.$refs.currencySelectionDrawer.openDrawer() : this.showCurrencySelector = true;
    },
   setTransactionClass(index) {
    // this.$store.setTransactionClass(transactionClass)
    this.$store.commit('setCurrentOption', index);
    this.$store.getters.getSelectedBeneficiary ?  this.$emit('changeStep', 2): this.$emit('changeStep', 2);
    this.$emit('changeStep', 2)
   },
   removeDuplicatesByFirstWord(arr) {
  const map = new Map();
  arr.forEach(item => {
    const firstWord = item.split('_')[0];
    if (!map.has(firstWord)) {
      map.set(firstWord, item);
    }
  });
  return ['ALL',... Array.from(map.values())]
},

    setDeliveryOptionsPriority(deliveryOptions){
    
    },
    changeCurrency(currency) {
      this.isMobileDevice ? this.$refs.currencySelectionDrawer.closeDrawer() : this.showCurrencySelector = false;
      this.selectedCurrency = currency
      
    },

    calculate() {
      const cleanAmount = this.amount.replaceAll(',', '');
      this.$refs.lookup.setAmount(cleanAmount);
      this.$refs.lookup.doLookup();
      this.isCalculating = false
    },

    lookupReady() {
      let { defaultAmount, defaultCurrency, iso , allowedCurrencies } = this.selectedCountry
      const isExoticCurrency = this.isExoticCurrency(defaultCurrency)
      console.log("🚀 ~ lookupReady ~ isExoticCurrency:", isExoticCurrency)
      const fallbackCurrency = this.$refs.lookup.currencies[0].currency
      const lookUpCurrency = isExoticCurrency ?  fallbackCurrency : defaultCurrency
      const payoutCurrency = this.$refs.lookup.payoutCurrency
      this.amount = this.previousStep === 'BeneficiarySelection' ? this.$store.getters.getLookupAmount || defaultAmount : defaultAmount
      console.log("🚀 ~ lookupReady ~ this.amount:", this.amount)
      this.$refs.lookup.setAmount(this.amount);
      this.$refs.lookup.setCurency(lookUpCurrency);
      console.log(this.$refs.lookup.currencies);
      console.log(this.$refs.lookup.payoutCurrency);
      
      this.$store.commit('setLookupAmount', this.amount);
      this.$store.commit('setLookupCurrency', lookUpCurrency);
      
      console.log("🚀 ~ lookupReady ~ lookUpCurrency:", lookUpCurrency)
      if (isExoticCurrency && this.$refs.lookup.payoutCurrency === defaultCurrency) {
      this.$refs.lookup.changeAmountTypeByVar(false)
      } else {
      this.selectedCurrency = this.$refs.lookup.currencies[0].currency
      this.selectedCountry.allowedCurrencies = allowedCurrencies.filter((currency)=> currency !==  defaultCurrency)
      }
      if (payoutCurrency && !allowedCurrencies.includes(payoutCurrency)) this.selectedCountry.allowedCurrencies = [...allowedCurrencies ,payoutCurrency ]
      console.log("🚀 ~ lookupReady ~ allowedCurrencies:", allowedCurrencies)
      if (this.deliveryOptions.length && this.deliveryOptions.every((option)=> option.destinationCountry === iso )) {
        EventBus.$emit('unload');
        return
      }   
      this.$refs.lookup.doLookup()
    },

    isExoticCurrency(currency) {
      return !defaultCurrencies.includes(currency)
    },

    setSelectedMethod(method) {
      this.selectedMethod = method
    },
    formatAmount(event) {
      let input = event.target.value;
      input = input.replace(/[^0-9.]/g, '');
      if (input.startsWith('0') && !input.startsWith('0.')) {
        input = input.substring(1);
      }
      this.amount = input;
      this.$store.commit('setLookupAmount', this.amount);

      event.target.value = this.formatNumber(input);
    },
    formatNumber(value) {
      if (!value) return '';
      const parts = value.split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    },
  
    getFlagByCurrency(currency) {
      console.log("🚀 ~ getFlagByCurrency ~ currency:", currency)
      console.log("🚀 ~ getFlagByCurrency ~ this.isExoticCurrency(currency):", this.isExoticCurrency(currency))

      let countryIso = ''
      switch (currency) {
        case 'EUR':
          countryIso = 'DEU'
          break;
        case 'ILS':
          countryIso = 'ISR'
          break;
        case 'USD':
          countryIso = 'USA'
          break;
        default:
          console.log("🚀 ~ getFlagByCurrency ~ this.selectedCountry:", this.selectedCountry)
        countryIso = this.selectedCountry.iso
          break;
      }
      return require(`../../../../assets/mygmt_v2_images/countries/${countryIso}.svg`)

    },

    findRecentByBeneficiary(recentTransactions, beneficiaryId, deliveryOptions) {
      const recentTransaction = recentTransactions.find(transaction => transaction.beneficiaryId === beneficiaryId) ;

      if (recentTransaction) {
        return deliveryOptions.find(option => 
          option.destinationCountry === recentTransaction.countryIso && 
          option.transactionClass === recentTransaction.transactionClass && !option.unAviable
        );
      }

      return null;
    },

    findRecentRegularly(recentTransactions, deliveryOptions) {
      let recentTransactionIndex = null;
      let recentDeliveryOption = null;

      deliveryOptions.forEach((option) => {
        if (option.amountLimited || !option.canSend || option.amount <= 0) {
          option.unAviable = true;
          return;
        }

        const transactionIndex = recentTransactions.findIndex(transaction =>
          transaction.countryIso === option.destinationCountry &&
          transaction.transactionClass === option.transactionClass && !option.unAviable
        );

        if (transactionIndex !== -1 && (recentTransactionIndex === null || transactionIndex < recentTransactionIndex)) {
          recentTransactionIndex = transactionIndex;
          recentDeliveryOption = option;
        }
      });

      return recentDeliveryOption;
    },

    findPopularOption(deliveryOptions) {
      let popularOption = null;

      deliveryOptions.forEach(option => {
        if (option.priority !== null && !option.isRecentTransaction && !option.unAviable) {
          if (!popularOption || option.priority < popularOption.priority) {
            popularOption = option;
          }
        }
      });

      return popularOption;
    },
    markUnAviable(option) {
      if (option.amountLimited || !option.canSend || option.amount <= 0) {
        option.unAviable = true;
      } else {
        option.unAviable = false;
      }}
//     processDeliveryOptions(deliveryOptions, recentTransactions) {
//     console.log("🚀 ~ processDeliveryOptions ~ recentTransactions:", recentTransactions)
//     console.log("🚀 ~ processDeliveryOptions ~ deliveryOptions:", deliveryOptions)
//   let recentDeliveryOption = null;
//   let recentTransactionIndex = null;
//   let popularOption = null;

//   // Step 1: Iterate through deliveryOptions
//   deliveryOptions.forEach((option, index) => {
//     option.amount = option.amount
//     // Find matching recent transaction for this deliveryOption
//     const transactionIndex = recentTransactions.findIndex(transaction =>
//       transaction.countryIso === option.destinationCountry &&
//       transaction.transactionClass === option.transactionClass
//     );

//     // Step 2: Check for the first match in recentTransactions (lowest index)
//     if (transactionIndex !== -1) {
//       if (recentTransactionIndex === null || transactionIndex < recentTransactionIndex) {
//         recentTransactionIndex = transactionIndex;
//         recentDeliveryOption = option;
//       }
//     }

//     // Step 3: Check for the lowest priority that is not null
//     if (option.priority !== null && !option.isRecentTransaction) {
//       if (!popularOption || option.priority < popularOption.priority) {
//         popularOption = option;
//       }
//     }
//   });

//   // Step 4: Set isRecentTransaction true for the corresponding delivery option
//   if (recentDeliveryOption) {
//     console.log("🚀 ~ processDeliveryOptions ~ recentDeliveryOption:", recentDeliveryOption)
//     recentDeliveryOption.isRecentTransaction = true;
//   }

//   // Step 5: Set isPopular true for the delivery option with the lowest priority (if it's not the recent one)
//   if (popularOption && (!recentDeliveryOption || popularOption !== recentDeliveryOption)) {
//     popularOption.isPopular = true;
//   }

//   // Step 6: Sort the deliveryOptions array so that isRecentTransaction comes first, then isPopular
//   // deliveryOptions.sort((a, b) => {
//   //   if (a.isRecentTransaction) return -1;
//   //   if (b.isRecentTransaction) return 1;
//   //   if (a.isPopular) return -1;
//   //   if (b.isPopular) return 1;
//   //   return 0; // Keep the order of other options as it is
//   // });

//   this.$store.commit('setTransferStep1Response', deliveryOptions.map((i)=> i));

  
//   return deliveryOptions;
// }
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/partials/variables";


.limit-height {
  min-height: 150px;
}
.error-message {
  color: #000;
font-family: Assistant;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding: 10px
}

.grey-unaviable {
  background: #F3F3F3 !important;
box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13) !important;
min-height: 150px !important;

}

.backdrop {
  backdrop-filter: blur(10px);
}

.drawer-wrapper-card {
  left: 0px;
  bottom:0px;
  border-radius: 20px;
  width: 100vw;
  // position: absolute;
  // inset-block-start: 500px;
}

.transfer-container {
//  padding: 0px 20px !important;
}


.white-screen {
  // height: 100vh;
  // width: 100vw;
  background-color: white;
  opacity: 0.5;
  position: 'absolute';
  z-index: 999999999;

}



.currency-options-container {
  color: #000;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Assistant;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  height: 70px;
  border-bottom: 1px solid #EEE;
  // z-index: 10;

  .country-img {
    margin-right: 10px;
  }
}
h4 {
    margin-bottom: 20px;
    color: #000;
font-size: 20px;
font-weight: 600;
  }

.amount-container {

  .mock-input {
    width: 100%;
    height: 64px;
    border-radius: 5px;
    border: 1px solid #E4E8EB;
    background: #FFF;
    padding: 0px 16px 0px 10px;
    cursor: text;
    margin-bottom: 20px;

    input {
      border: none;
      color: #161932;
      font-family: Assistant;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      width: 75%;
    }

    input[type=number] {
      -moz-appearance: textfield;
    }

    .currency-select {
      border: none;
      background-color: white;
      cursor: pointer;
      height: 40px;
      width: 95px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

}

.calculate-btn {
  height: 45px;
  width: 100%;
  margin-bottom: 26px;
}

.cash-back {
  height: 80px;
  border-radius: 8px;
  border: 1px solid #E4E8EB;
  box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13);
}
.closer{
  background-color: rgba(128, 128, 128, 0.26);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}


.currency-select-desktop {
  width: 335px;
  position: absolute;
  background-color: white;
  top: 40px;
  z-index: 1001;
  left: -240px;
  border-radius: 5px;
border: 1px solid #EBEBEB;
background: #FFF;
box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.20);

  .currency-options-container {
    padding: 10px;
  }

  .currency-options-container:hover {
    color: #0E1F81;
    background-color: #F1F6F9;

  }
}

.method-filter-container {
  margin-top: 20px;
  overflow-x: auto;
  margin-bottom: 10px;


  .method {
    margin-bottom: 20px;
    min-width: 84px;
    color: #0E1F81;
    text-align: center;
    font-family: Assistant;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
     // margin-right: 19px;
    height: 68px;
    // min-width: 72px;
    justify-content: space-between;
    // width: 90px;
    // min-width: 90px;

    .text-container {
      width: 115px;
      font-size: 12px;
    }



    .img-container {
      border-radius: 100%;
      min-width: 46px;
      min-height: 46px;
      border: 1.5px solid #EBEBEB;


      img {
        width: 23px;
        height: 23px;
      }
    }
  }
}



.selected-method {
  font-weight: 700;

  .img-container {
    border: 1.5px solid #0E1F81 !important;
    box-shadow: 0px 0.5px 3px #0e1f81a3;

  }
}

.delivery-options-container {
  border-radius: 8px;
  border: 1px solid #EBEBEB;
  background: var(--Neutral-White, #FFF);
  box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13);
  min-height: 263px;
  margin-bottom: 20px;

  .option-header {
    border-bottom: 1px solid #EBEBEB;
    padding: 14px;
    font-weight: 700;

    img {
      margin-right: 10px;
    }

  }

  .option-info {
    height: 100%;
    padding: 10px 14px;
    color: #000;
    font-family: Assistant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

      .flex  {
      margin-top: 4px;
      }

      .bold {
font-size: 16px;
font-weight: 700;
      }

    //  div {
    //   margin-bottom: 20px;
    //  }
  }

  .option-button {
    border-top: 1px solid #EBEBEB;
    height: 60px;
    padding: 12px 14px;

    button {
      width: 100%;
    }

  }
}

.recent-transaction {
  border-radius: 8px;
border: 1px solid #6F8CDD;
background: var(--Neutral-White, #FFF);
box-shadow: 0px 4px 12px 0px rgba(111, 140, 221, 0.16);
}


@media only screen and (min-width: $screen-tablet) {

  h4 {
  font-size: 18px;
  }

  .mock-input {
    height: 58px;
  }

  .calculate-btn {
    height: 45px;
    width: 335px;
  }


  .method-filter-container {
  margin-top: 20px;


  .method {
    height: 80px;
    margin-bottom: 24px;
    .img-container {
      border-radius: 100%;
      min-width: 56px;
      min-height: 56px;
      border: 1.5px solid #EBEBEB;


      img {
        width: 29px;
        height: 29px;
      }
    }
  }
}



  .delivery-options-container {
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    background: var(--Neutral-White, #FFF);
    box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13);
    min-height: 263px;

    .option-header {
      border-bottom: 1px solid #EBEBEB;
      padding: 14px;
      font-weight: 700;

      img {
        margin-right: 10px;
      }

    }

    .option-info {
      height: 100%;
      padding: 14px;
    }

    .option-button {
      border-top: 1px solid #EBEBEB;
      height: 74px;
      padding: 12px 14px;

      button {
        width: 100%;
        height: 46px;
      }

    }
  }

  .recent-transaction {
  border-radius: 8px;
border: 1px solid #6F8CDD;
background: var(--Neutral-White, #FFF);
box-shadow: 0px 4px 12px 0px rgba(111, 140, 221, 0.16);
}


}
</style>
