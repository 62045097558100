<template>
  <div class="flex flex-column justify-between h-100">
    <div v-if="!isCreatingBeneficiary" class="beneficiary-list flex flex-column justify-between">
      <div>
        <div class="title">Choose beneficiary</div>
        <div class="item-beneficiary pointer" v-for="(beneficiary, index) in beneficiaries"
          @click="selectBeneficiary(beneficiary)">
          <!-- <div>{{ beneficiary }}</div> -->
          <div class="item-row flex">
            <div class="flex full-center justify-between beneficiary-container">
              <div class="flex">
                <div class="circle pointer" @click=""><strong>{{ beneficiary.firstName[0].toUpperCase()
                    }}</strong></div>
                <div class="flex flex-column">
                  <div class="name flex">{{ beneficiary.firstName }} {{ beneficiary.lastName }}</div>
                  <div class="info">
                    <span v-if="cardDeposit && beneficiary.maskedCardNumber">
                      {{ $ml.with('VueJS').get('toCreditCard') }} {{ beneficiary.maskedCardNumber | formatCardNumber }}
                    </span>
                    <span v-else-if="bankTransfer && (beneficiary.accountIdentifier || beneficiary.iban)">
                      {{ $ml.with('VueJS').get('toBankAccount') }} {{ beneficiary.accountIdentifier ?
                        beneficiary.accountIdentifier : beneficiary.iban }}
                    </span>
                    <span v-else-if="(walletTransfer && beneficiary.walletIdentifier) && !isWalletPhone">
                      {{ $ml.with('VueJS').get('toWalletAccount') }} {{ beneficiary.walletIdentifier }}
                    </span>
                    <span v-else-if="cashBank && beneficiary.cashBankName">
                      {{ $ml.with('VueJS').get('pickupPoint') }} {{ beneficiary.cashBankName }}
                    </span>
                    <span v-else-if="isWalletPhone && beneficiary.phoneNumber">
                      {{ $ml.with('VueJS').get('beneficiary.phoneNumber') }} {{ beneficiary.phoneNumber }}
                    </span>
                    <span v-else></span>
                  </div>
                </div>
              </div>
              <div> <img src="../../../../assets/mygmt_v2_images/Right-Chevron.svg" alt=""></div>
            </div>
          </div>
        </div>
      </div>
      <div> <button class="btn btn-primary">+ Add New Beneficiary</button> </div>
    </div>
    <BeneficiaryCreation v-else></BeneficiaryCreation>
  </div>
</template>
<script>
import _ from 'lodash';
import { getCountryByIso, isApp, isMobile } from '../../../../services/utils';
import BeneficiaryCreation from './BeneficiaryCreation.vue';


export default {
  components: { BeneficiaryCreation },
  created() {
    this.selectedCountry = getCountryByIso(this.$store.state.transfer.destinationCountry)
    if (this.$store.state.transfer.cloneInfo) {
      this.$emit('changeStep', 3)
    }
   


    console.log('blaa', this.transactionClassSelected);

  },
  data() {
    return {
      selectedCountry: {},
      selectedBeneficiary: {},
      isMobileDevice: isMobile(),
      isCreatingBeneficiary: false,
      transactionClassSelected: this.$store.getters.getSelectedTransferOption.transactionClass
    };
  },
  computed: {
    cardDeposit() {
      return this.transactionClassSelected == 'CARD_DEPOSIT';
    },
    bankTransfer() {
      return this.transactionClassSelected == 'BANK_TRANSFER';
    },
    walletTransfer() {
      return this.transactionClassSelected == 'WALLET_TRANSFER';
    },
    cashBank() {
      return this.transactionClassSelected == 'CASH_TRANSFER' && this.$store.getters.getSelectedTransferOption.correspondentType == 'TRANGLO';
    },
    isWalletPhone() {
      return this.$store.getters.getSelectedTransferOption && this.$store.getters.getSelectedTransferOption.correspondentIdentifier == 'THUNES_WECHATPAY';
    },
    beneficiaries() {
      console.log("🚀 ~ beneficiaries ~ this.$store.getters.getListBeneficiary:", this.$store.getters.getListBeneficiary)
      return Object.filter(this.$store.getters.getListBeneficiary, item => {
        return (item.countryIso3.indexOf(this.selectedCountry.iso) > -1);
      });
    },
  },
  watch: {
  },
  mounted() {
  },
  beforeDestroy() { },
  methods: {
    async selectBeneficiary(beneficiary) {
      this.$store.commit('setCurrentBeneficiary', beneficiary.id); // after reload is undefined
      sessionStorage.setItem('currentBeneficiary', beneficiary.id); // for reload .
      this.$emit('changeStep', 3)

      // this.$analyticsService.transmitSwitchCase('transaction', 'transaction_s_3', { error_count: 0, beneficiary: 'existing_beneficiary' })
      // await this.updateBeneficiary();
      // modalHandler('.modal-mask', 'hide', 'ffffff', 'dark')
    },
    
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/partials/variables";
.beneficiary-list {
  height: 65vh;
}

.item-beneficiary {
  margin-bottom: 20px;
}

.title {
  color: #000;
font-family: Assistant;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-bottom: 12px;
margin-top: 16px;
}
.beneficiary-container {
padding: 9px 14px 9px 12px;
border-radius: 20px;
border-radius: 29px;
border: 1px solid #EBEBEB;
background: #FFF;
/* Light dark shadow */
box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13);
width: 100%;
height: 54px;  

    .name {
      color: var(--Main-Blue, #0E1F81);
font-family: Assistant;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
    }

  

    .info {
      color: #000;
font-family: Assistant;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
    }
}
.btn-primary {
      width: 100%;
      height: 45px;
      // padding: ;
    }
.circle {
  background: #0E1F81;
  border-radius: 35px;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;

  strong {
    color: white;
    font-weight: 600;
    height: 25px;
    font-size: 18px;
  }
}


@media only screen and (min-width: $screen-tablet) {
  .title {
   font-size: 20px;
}
}
</style>
