<template>
  <div :style="tagStyle" class="tag">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "Tag",
  props: {
    backgroundColor: {
      type: String,
      default: "#f0f0f0"
    },
    textColor: {
      type: String,
      default: "#000000"
    },
    text: {
      type: String,
      default: "Tag Text"
    }
  },
  computed: {
    tagStyle() {
      return {
        backgroundColor: this.backgroundColor,
        color: this.textColor,
        padding: "5px 10px",
        borderRadius: "5px",
        display: "inline-block",
        fontSize: "14px"
      };
    }
  }
};
</script>

<style scoped>
.tag {
  font-weight: bold;
}
</style>
