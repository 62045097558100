<template>
  <div class="mygmt2-header-wrapper flex">
    <Drawer ref="contactUsDrawer" drawerId="contactUsDrawer">
      <template v-slot:content>
        <div class="full-width">
          <div class="contact-container">
            <div class="contact-us-svg"></div>
            <h4>{{ $ml.get('itemNav7') }}</h4>
            <p>{{ $ml.get('contact_us_paragraph') }}</p>
            <div @click="openTel" class="btn btn-white btn-border btn-container-contact-us">
              <button  class="contact-us-button">
                <div class="btn-label-area">
                  <img src="../assets/mygmt_v2_images/Phone.svg" alt="contact us" />
                  <span class="btn-text">{{ $ml.get('call_us') }}</span>
                </div>
              </button>
            </div>
          
            <span>{{ $ml.get('contact_us_or') }} </span>

            <div class="flex flex-column w-100">
              <div @click="actionBasedOnLang" :class="{'order-1': !isChinese && !isThai}" class="btn btn-white btn-border btn-container-contact-us">
                <button class="contact-us-button" >
                  <div class="btn-label-area">
                    <img :src="imgSrc()" alt="contact us" />
                    <span class="btn-text">{{ $ml.get('contactMenuCaption2') }}</span>
                  </div>
                </button>

              </div>
              <div  @click="openWhatsApp" class="btn btn-white btn-border btn-container-contact-us">
                <button class="contact-us-button">
                  <div class="btn-label-area">
                    <img src="../assets/mygmt_v2_images/Whatsapp_Contact.svg" alt="contact us" />
                    <span class="btn-text">{{ $ml.get('contactMenuCaption4') }}</span>
                  </div>
                </button>
              </div>
              <div @click="openTelegram" class="btn btn-white btn-border btn-container-contact-us">
                <button class="contact-us-button" >
                  <div class="btn-label-area">
                    <img src="../assets/mygmt_v2_images/Telegram.svg" alt="contact us" />
                    <span class="btn-text">{{ $ml.get('contactMenuCaption1') }}</span>
                  </div>
                </button>
              </div>
            </div>



          </div>
        </div>
      </template>
    </Drawer>
    <modal ref="weChatModal" :confirm="$ml.get('qrCodeDownload')" @action="downloadQRCode" noCancelBtn>
      <p style="margin: 25px 0">{{ $ml.get('scanExplanation') }}</p>
      <img class="qrCodeImg" :src="qrCodeImg()" alt="QRScan" />
    </modal>
    <div v-if="!isMobileDevice" class="flex full-center mygmt-logo2-wrapper"> 
     <img src="../assets/mygmt_v2_images/myGmtLogo.png" class="mygmt-logo2" @click="goHome()"
      /></div>
    <div class="mygmt-header">
      <div class="line-logo">
        <div style="display: flex; order: 2;">
          <div class="mygmt2-header-actions" v-if="isMobileDevice">
            <div class="unregistered-nav-box">
              <div class="select-lang" v-if="isCurrentRouteLogin()">
                <div class="selected-lang icon icon-lang" @click="openListLangMobile = !openListLangMobile">
                  <span>{{ $store.state.localeTitle }}</span>
                </div>
                <div class="list-lang" :class="{ 'open': openListLangMobile }">
                  <ul>
                    <li v-for="(lang, index) in $store.state.locales" :key="index"
                      @click="$ml.change(index), changeLang(index), openListLangMobile = false">
                      <img v-if="getLang === index" src="../assets/images/icons/Selected.svg">
                    </li>
                  </ul>
                </div>
              </div>
              <img src="../assets/mygmt_v2_images/myGmtLogo.png" class="mygmt-logo" @click="goHome()"
                v-if="isCurrentRouteHome()" />
            </div>
          </div>
        </div>
        <img src="../assets/mygmt_v2_images/myGmtLogo.png" class="mygmt-logo" @click="goHome()"
          v-if="!isCurrentRouteLogin() && !isCurrentRouteHome()" />
        <div class="mygmt2-header-actions" :class="{ 'logged-out-header': !isLoggedIn, 'logged-in-header': isLoggedIn }"
          v-if="!isMobileDevice">
          <img class="icon acsbi pointer header-action" data-acsb="trigger" src="../assets/mygmt_v2_images/Acsbi.svg" />
          <div class="select-lang header-action">
            <div class="selected-lang icon icon-lang" @click="openListLang = !openListLang">
              <span>{{ $store.state.localeTitle }}</span>
            </div>
            <div class="list-lang" :class="{ 'open': openListLang }">
              <ul>
                <li v-for="(lang, index) in $store.state.locales" :key="index"
                  @click="$ml.change(index); changeLang(index); openListLang = false">
                  <img v-if="getLang === index" src="../assets/images/icons/Selected.svg">
                </li>
              </ul>
            </div>
          </div>
          <span v-if="!isLoggedIn" @click="goToAboutPage()" class="header-action pointer">{{ $ml.get('itemNav11')
            }}</span>
          <span @click="goToFAQPage()" class="header-action pointer">FAQ</span>
          <span @click="goToGmtLocationsPage()" class="header-action pointer">{{ $ml.get('itemNav5') }}</span>
          <span v-if="!isLoggedIn" @click="goToRateAndFeesPage()" class="header-action pointer">{{ $ml.get('itemNav3')
            }}</span>


        </div>
        <div class="navbar-button-container" v-if="!isCurrentRouteHome() && isMobileDevice && !isCurrentRouteLogin()">
          <div class="navbar-button" @click="routeGoBack()">
            <section v-if="isCurrentRouteStepManaged()" class="go-back-wrapper">
              <img class="arrow-next" :class="{ 'arrow-mirror': getLang === 'il' }"
                src="../assets/images/icons/i-arrow-left.png">
            </section>
            <section v-else class="go-back-wrapper">✕</section>
          </div>
        </div>
        <button class="btn btn-border" @click="openContactMenu()">{{ $ml.get('itemNav7') }}</button>
      </div>
      <div v-if="longHeader && isMobileDevice">
        <NewCustomerCard class="long-header-container" />
      </div>
      <div v-if="greetings && !longHeader">
        <Greetings />
      </div>
    </div>
  </div>
</template>
<script>
import Login from "../pages/Login";
import store from '../store';
import { EventBus } from '../event-bus.js';
import { getOrderDetails, polledBalances, pollStatus, updateLang } from "../services/api";
import { formatDecimal } from "@/filters/AppFilters";
import Modal from "@/components/Modal.vue";
import Axios from "axios"
import { isApp, isMobile, paintStatusBar } from "../services/utils";
import Greetings from "@/components/Greetings.vue";
import NewCustomerCard from "@/components/NewCustomerCard.vue";
import Drawer from "@/components/Drawer.vue";

export default {
  name: 'Header2',
  components: { Drawer, NewCustomerCard, Greetings, Login, Modal },
  props: {
    longHeader: {
      type: Boolean,
      required: false
    },
    greetings: {
      type: Boolean,
      required: false
    },
    currentStep: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      openListLang: false,
      openListLangMobile: false,
      dataLang: {
        'lang': this.$store.getters.getLang,
        'dir': this.$store.getters.getDir
      },
      registrationData: {},
      walletStatusInterval: null,
      isApp: isApp(),
      isMobileDevice: isMobile()
    }
  },
  created() {
    this.registrationData = JSON.parse(localStorage.getItem('registrationData'));
    EventBus.$on('updateWalletBalances', () => {
      this.updateBalances()
    });
    EventBus.$on('openContactUs', () => {
      console.log('lalalalalala');
      this.openContactMenu()
    });
    
  },
  beforeDestroy() {
    clearInterval(this.walletStatusInterval)
  },
  computed: {
    userName() {
      return localStorage.getItem('userName');
    },
    balances() {
      return this.$store.getters.getWalletBalances.map((el) => {
        return el.symbol + formatDecimal(el.amount);
      }).join(", ");
    },
    showWallet() {
      return localStorage.getItem('showWallet') === 'true'
    },
    areBalancesUpdated() {
      return this.$store.getters.areWalletBalancesUpdated;
    },
    isLoggedIn() {
      return store.getters.getLoggedIn && !store.getters.getNotSigned;
    },
    isThai() {
      return this.$data.dataLang.lang == "th";
    },
    isChinese() {
      return this.$data.dataLang.lang == "cn";
    },
    getLang() {
      return this.$store.getters.getLang;
    },
    getDir() {
      return this.$store.getters.getDir;
    },
  },
  mounted() {
    $('body').removeClass('stop-scrolling')
    if (!this.$store.getters.getWalletBalancesInterval) {
      this.updateBalances()
      this.$store.commit('setWalletBalancesInterval', window.setInterval(() => {
        this.updateBalances()
      }, 30000))
    } else {
      this.$store.commit("setWalletBalancesAreUpdated", true)
    }
    if (this.isLoggedIn) {
      getOrderDetails(res => {
        if (!res.result.active && this.$route.name === 'wallet-order') {
          this.$router.push({ name: 'Home' });
        }
        this.$store.commit('setOrderData', res.result)
        if (this.$store.getters.isOrderActive) {
          this.walletStatusInterval = setInterval(() => {
            if (this.$store.getters.getOrderId) {
              pollStatus(this.$store.getters.getOrderId, (res) => {
                this.$store.commit('setOrderStatus', { status: res.result.status })
              })
            } else {
              clearInterval(this.walletStatusInterval)
            }
          }, 10000)
        }
      })
    }
    // paintStatusBar('ffffff', 'dark')
  },
  methods: {
    updateBalances() {
      if (this.isLoggedIn) {
        polledBalances(response => {
          this.$store.commit('setCashBackBalances', response.CASHBACK);
          if (response.WALLET && response.WALLET.length > 0) {
            this.$store.commit("setWalletBalancesAreUpdated", true)
            this.$store.commit('setWalletBalances', response.WALLET);
          } else {
            this.$store.commit("setWalletBalancesAreUpdated", false)
          }
        }, true)
      }
    },
    imgSrc() {
      if (this.isThai) {
        return require(`../assets/mygmt_v2_images/Line.svg`)
      }
      if (this.isChinese) {
        return require(`../assets/mygmt_v2_images/Wechat.svg`)
      }
      return require(`../assets/mygmt_v2_images/Messenger.svg`)
    },
    changeLang(lang) {
      console.log("🚀 ~ changeLang ~ lang:", lang)
      console.log("🚀 ~ changeLang ~ this.dataLang.lang:", this.dataLang.lang)
      this.dataLang.lang = lang;
      store.commit('changeLang', this.dataLang);
      store.commit('setLangTitle', lang);
      if (store.getters.getLoggedIn) updateLang({ "lang": lang });
      EventBus.$emit('changeLang', lang);
      
      // this.$forceUpdate();
    },
    qrCodeImg() {
      return require(`../assets/images/weChatQrCode.png`)
    },
    downloadQRCode() {
      Axios.get(this.qrCodeImg(), {
        responseType: 'blob'
      })
        .then(response => {
          const blob = new Blob([response.data], {
            type: 'image/png'
          })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = "weChatQrCode"
          link.click()
          window.open( URL.createObjectURL(blob))
          try {
            alert('trying go native')
            // gonative.permissions.status().then((response)=> {
            //   alert(response)
            // });
            gonative.share.downloadImage('https://images.unsplash.com/opengraph/1x1.png?blend=https%3A%2F%2Fimages.unsplash.com%2Fphoto-1481349518771-20055b2a7b24%3Fblend%3D000000%26blend-alpha%3D10%26blend-mode%3Dnormal%26crop%3Dfaces%252Cedges%26h%3D630%26mark%3Dhttps%253A%252F%252Fimages.unsplash.com%252Fopengraph%252Fsearch-input.png%253Fh%253D84%2526txt%253Drandom%2526txt-align%253Dmiddle%25252Cleft%2526txt-clip%253Dellipsis%2526txt-color%253D000000%2526txt-pad%253D80%2526txt-size%253D40%2526txt-width%253D660%2526w%253D750%2526auto%253Dformat%2526fit%253Dcrop%2526q%253D60%26mark-align%3Dmiddle%252Ccenter%26mark-w%3D750%26w%3D1200%26auto%3Dformat%26fit%3Dcrop%26q%3D60%26ixid%3DM3wxMjA3fDB8MXxzZWFyY2h8Nnx8cmFuZG9tfGVufDB8fHx8MTcxODU2Nzk3N3ww%26ixlib%3Drb-4.0.3&blend-w=1&h=630&mark=https%3A%2F%2Fimages.unsplash.com%2Fopengraph%2Flogo.png&mark-align=top%2Cleft&mark-pad=50&mark-w=64&w=1200&auto=format&fit=crop&q=60')
            gonative.share.downloadFile(URL.createObjectURL(blob))
            alert(gonative.share)
          } catch (error) {
            alert(error)
          }
          URL.revokeObjectURL(link.href)
        }).catch(console.error)
      this.$refs.weChatModal.closeModal();
    },
    showNavbar() {
      $('.main-nav').css('display', 'block');
      $('html').toggleClass('overflow');
      $('.header').toggleClass('open');
      $('.main-nav').toggleClass('open');
    },
    goUserProfile() {
      this.$router.push('user-profile');
    },
    openContactMenu() {
      this.$refs.contactUsDrawer.openDrawer();
    },
    openTelegram() {
      window.open('https://t.me/joingmtonline');
      // mygmt2 add telegram link here
    },
    openFacebook() {
      window.open('http://m.me/GMT.Advanced.Financial.Services');
    },
    openWhatsApp() {
      window.open('https://wa.me/972547228005');
    },
    openLine() {
      window.open('https://lin.ee/tZ0yUnY');
    },
    openWeChat() {
      this.$refs.contactUsDrawer.closeDrawer();
      this.$refs.weChatModal.openModal();
    },
    openTel() {
      window.open('tel:03-3722-800');
    },
    goToGmtLocationsPage() {
      window.open('https://gmtonline.co.il/' + this.$ml.get('locationLink'), '_blank')
    },
    goToRateAndFeesPage() {
      this.$router.push('/calculator-unregistered')
    },
    goToAboutPage() {
      window.open('https://gmtonline.co.il/en/my-gmt/', '_blank')
    },
    goToFAQPage() {
      window.open('https://gmtonline.co.il/%d7%a9%d7%90%d7%9c%d7%95%d7%aa-%d7%95%d7%aa%d7%a9%d7%95%d7%91%d7%95%d7%aa/', '_blank')
    },
    selectLangMenu() {
      if (this.isMobileDevice) {
        $("#defaultActions").hide();
        $(".im-user").css('visibility', 'hidden');
        $(".greeting-line").css('visibility', 'hidden');
        $(".user-name-line").css('visibility', 'hidden');
        $("#languageSelectActions").show();
      } else {
        $("#languageMenuDesktop").show();
      }
    },
    routeGoBack() {
      if (this.isCurrentRouteHome()) {
        this.showNavbar()
      } else {
        if (this.isCurrentRouteStepManaged()) {
          this.$emit('stepBack')
        } else {
          this.$router.go(-1)
        }
      }
    },
    isCurrentRouteHome() {
      return this.$router.history.current.name === 'Home'
    },
    isCurrentRouteLogin() {
      return this.$router.history.current.name === 'Login'
    },
    isCurrentRouteStepManaged() {
      return this.stepManagedRoutes.includes(this.$router.history.current.name)
    },
    actionBasedOnLang() {
      if (this.isThai) {
        this.openLine();
      } else if (this.isChinese) {
        this.openWeChat()
      } else
        this.openFacebook();
    },
    goHome() {
      this.$router.push({ name: localStorage.getItem('homePage') || 'wallet-main' })
    },
  },
  watch: {
    isLoggedIn(value) {
      if (value) {
        this.$store.commit('setWalletBalances', []);
        this.updateBalances()
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/partials/variables";

.mygmt-logo2 {
  height: 40px;
  cursor: pointer;
  margin-left:40px
}


.long-header-container {
  padding: 0px 20px;
}

#contactUsDrawer {
 max-height: 80vh;
 z-index: 99999 !important;
}

.btn-container-contact-us {
  cursor: pointer;
  padding: 20px 0;

  .contact-us-button {
    border: none;
    background: none;
    width: 100px;
    cursor: pointer;
  }
}

.icon-user {
  margin-inline-end: 5px;
}

.icon {
  span {
    color: black;
    font-size: 16px;
  }
}

.mygmt2-header-wrapper {
 // box-shadow: none !important;
}

.list-lang {
  display: none;
  cursor: pointer;

  ul {
    background: white;
    width: 100%;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 15px;
      border-bottom: 1px solid #F5F5F5;
      width: 100%;
      margin: auto;

      &:first-child {
        &:before {
          content: 'עברית';
        }
      }

      &:nth-child(2) {
        &:before {
          content: 'English';
        }
      }

      &:nth-child(3) {
        &:before {
          content: 'Русский';
        }
      }

      &:nth-child(4) {
        &:before {
          content: 'ภาษาไทย';
        }
      }

      &:last-child {
        &:before {
          content: '中文';
        }

        border-bottom: none;
      }
    }
  }

  &.open {
    display: block;
    position: absolute;
    width: 125px;
    height: auto;
    background-color: #fff;
    box-shadow: 0 3px 18px 0 #bebebe !important;
    z-index: 2;
    padding: 5px 0;
    border-radius: 10px;
    margin-top: 10px;
  }
}

.mygmt-header {
  width: 100%;
  background-color: white;
  padding-top: 46px;
  padding-bottom: 18px;
  min-height: 60px;
  display: flex;
  align-items: center;

  .line-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 90%;
    margin: auto;

    .btn {
      color: black;
      border: 1px solid black;
      height: 22px;
      width: 67px;
      font-size: 13px;
      line-height: 21px;
      padding: 0 7px;
      order: 3;
      border-radius: 30px;

      &:hover {
        background-color: inherit;
      }
    }
  }

  .mygmt-logo {
    display: flex;
    order: 2;
    width: 65px;
    height: 20px;
  }
}

.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  padding: 0 2%;

  .contact-us-img {
    padding: 10px 0;
  }

  p {
    white-space: pre-wrap;
    text-align: center;
    margin-bottom: 15px;
    font-size: 14px;
  }

  .btn-white {
    .btn-label-area {
      width: auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;

      .btn-text {
        width: auto;
        white-space: nowrap;
        text-align: center;
        cursor: pointer;
        margin: 5px;
        color: black;
      }
    }

    border: 1px solid black;
    color: black;
    width: 100%;
    border-radius: 8px;
    font-size: 13px;
    opacity: .8;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin: 5px;
    }
  }
}

.navbar-button-container {
  order: 1;
  display: flex;
  width: auto;
  justify-content: center;

  .navbar-button {
    display: inline-block;
    vertical-align: middle;
    background-color: transparent;
  }
}

.contact-us-svg {

  background: url("../assets/mygmt_v2_images/ContactUs.svg") no-repeat;
  background-position: center;
  background-size: cover;
  height: 155px;
  width: 256px;
}

.unregistered-nav-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: auto;

  .selected-lang {
    height: 26px;
  }

  .arrow-next {
    margin-top: 5px;
  }
}

@media only screen and (min-width: $screen-tablet) {
  #contactUsDrawer {
 max-height: 100vh;
}
.mygmt-logo2 {
  // display: none
}
  .mygmt2-header-wrapper {
    padding: 7px 0 0 20px;
    background-color: #f6f8f900;
    margin-bottom: 8px;
    // margin-top: 5px;
    margin-right: 10px;
  }

  .logged-out-header {
    width: 600px;
  }

  .logged-in-header {
    width: 400px;
  }

  .mygmt-header {
    padding: 30px 0px;
    display: flex;
    justify-content: end;
    width: 80%;

    .line-logo {
      justify-content: flex-end;
      width: 100%;

      .mygmt2-header-actions {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-evenly;
        color: black;

        .globe {
          width: 20px;
          margin-inline-end: 5px;
        }
      }

      .mygmt-logo {
        display: none;
      }

      .btn {
        height: 33px;
        width: 100px;
      }
    }
  }

  .footer-container {
    visibility: hidden;
  }

  .selected-lang {
    cursor: pointer;
  }
}
</style>
